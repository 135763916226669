import React, { useEffect, useState } from "react";
import {
  Grid,
  Modal,
  GridColumn,
  Container,
  TransitionablePortal,
  Label,
  Button,
  Segment,
  Icon,
} from "semantic-ui-react";
import { useSelector, connect, useDispatch } from "react-redux";
import { upperFirst } from "lodash";
import {
  closeSwapModal,
  openSwapModal,
  setLoading,
  showBatterySelection,
  hideBatterySelection,
  showSetBatteryNumber,
} from "../store/appState";
import BatteryTypeSelection from "./BatteryTypeSelection";
import "../modal.responsiv.scss";
import "../modal.scss";
import { setBatteryCount } from "../store/swapOrderSlice";
import SharingPointBasicInfo from "./SharingPointBasicInfo";
import CancelSwapButton from "./CancelSwapButton";
import { openModuleDoor } from "../store/commandSlice";
import { navigate } from "@reach/router";
import BatteryImage from "./BatteryImage";

const BatterySelectionModal = ({ batteryOptions, setBatteryOptions }) => {
  const { isOpenSwapModal, isOpenBatterySelection } = useSelector(
    (state) => state.appState
  );
  const [showMorOptions, setShowMorOptions] = useState();

  const { selectedSharingpoint, isLoading } = useSelector(
    (state) => state.sharingpoints
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openSwapModal());
    dispatch(showBatterySelection());
  }, [selectedSharingpoint, isLoading]);

  return (
    <Container>
      {selectedSharingpoint && (
        <TransitionablePortal
          open={isOpenSwapModal && isOpenBatterySelection}
          transition={{ animation: "scale", duration: 200 }}
        >
          <Modal
            size="mini"
            centered={false}
            className="bt-sl-mdl"
            open={isOpenBatterySelection && isOpenSwapModal}
            onClose={() => {
              dispatch(closeSwapModal());
              dispatch(hideBatterySelection());
              dispatch(showSetBatteryNumber());
            }}
          >
            <Modal.Header>
              <Grid>
                <GridColumn width={4} textAlign="left">
                  <strong style={{ color: "black" }}>
                    {selectedSharingpoint.nick_name}
                  </strong>
                </GridColumn>

                <GridColumn width={12} textAlign="right">
                  <Label circular color="black">
                    {selectedSharingpoint.sharing_point_name}
                  </Label>
                  {selectedSharingpoint && (
                    <>
                      <Label color="blue" circular>
                        {selectedSharingpoint.sp_type}
                      </Label>
                      <Label
                        color={
                          selectedSharingpoint.sharing_point_status
                            ? "green"
                            : "red"
                        }
                        circular
                      >
                        {selectedSharingpoint.sharing_point_status
                          ? "online"
                          : "offline"}
                      </Label>
                    </>
                  )}
                </GridColumn>
              </Grid>
            </Modal.Header>
            <SharingPointBasicInfo
              selectedSharingpoint={selectedSharingpoint}
            />

            <Modal.Content>
              {!showMorOptions && (
                <Grid>
                  {isOpenBatterySelection && selectedSharingpoint && (
                    <BatteryTypeSelection
                      sp_name={selectedSharingpoint.sharing_point_name}
                      batteryOptions={batteryOptions}
                      setBatteryOptions={setBatteryOptions}
                      batteryInfo={selectedSharingpoint.box_info}
                    />
                  )}
                    <GridColumn width="16">
                      <Button
                        content="Open Modules"
                        circular
                        size="huge"
                        fluid
                        style={{ backgroundColor: "#444", color: "white" }}
                        onClick={() => setShowMorOptions(true)}
                      />
                    </GridColumn>

                  <GridColumn width="16">
                    <CancelSwapButton />
                  </GridColumn>
                </Grid>
              )}
              {showMorOptions && (
                <MoreActions
                  {...{
                    supported_batteries:
                      selectedSharingpoint.supported_batteries,
                    sharing_point_name: selectedSharingpoint.sharing_point_name,
                  }}
                />
              )}
            </Modal.Content>
          </Modal>
        </TransitionablePortal>
      )}
    </Container>
  );
};

const mapDispatch = {
  closeSwapModal,
  showBatterySelection,
  setBatteryCount,
  setLoading,
};
export default connect(null, mapDispatch)(BatterySelectionModal);

const MoreActions = ({ supported_batteries, sharing_point_name }) => {
  const dispatch = useDispatch();
  const { unique_validation_hash_key } = useSelector(
    (state) => state.swapOrder.swapOrder
  );

  const { openDoorIsLoading } = useSelector((state) => state.commands);

  return (
    <Segment style={{ borderRadius: 20 }}>
      {openDoorIsLoading && (
        <Grid columns="1">
          <GridColumn>Loading...</GridColumn>
        </Grid>
      )}

      {!openDoorIsLoading && (
        <Grid columns="1">
          <GridColumn>
            <span style={{ fontSize: 15, fontWeight: "bold" }}>
              Open modules
            </span>
          </GridColumn>

          {
            <>
              {unique_validation_hash_key &&
                supported_batteries &&
                supported_batteries.map((item) => (
                  <GridColumn key={item}>
                    <Button
                      content={
                        <Grid>
                          <GridColumn
                            width="3"
                            verticalAlign="middle"
                            textAlign="left"
                          >
                            <BatteryImage
                              type={item}
                              height="40px"
                              width="auto"
                            />
                          </GridColumn>
                          <GridColumn verticalAlign="middle" width="10">
                            {upperFirst(item).substring(0, 10)}
                          </GridColumn>
                          <GridColumn verticalAlign="middle" width="3">
                            <Icon name="unlock" style={{ color: "white" }} />
                          </GridColumn>
                        </Grid>
                      }
                      color="orange"
                      circular
                      size="huge"
                      fluid
                      onClick={() => {
                        dispatch(
                          openModuleDoor({
                            sharing_point_name,
                            battery_type: item.replaceAll("_", " "),
                            token: unique_validation_hash_key,
                          })
                        );

                        dispatch(closeSwapModal());
                      }}
                    />
                  </GridColumn>
                ))}
            </>
          }
          {!unique_validation_hash_key && (
            <GridColumn width="16">
              <Button
                content={`Scan qr-code`}
                color="orange"
                circular
                size="huge"
                fluid
                onClick={() => {
                  dispatch(closeSwapModal());
                  navigate("/scan");
                }}
              />
            </GridColumn>
          )}
          <GridColumn>
            <Button
              content={`Cancel`}
              basic
              circular
              size="huge"
              fluid
              onClick={() => {
                dispatch(closeSwapModal());
              }}
            />
          </GridColumn>
        </Grid>
      )}
    </Segment>
  );
};
